import type {TranslationResult} from "@/composables/translate";
import type {ListConfig} from "pg-isomorphic/api/lists";
import type {Address, SaveType} from "pg-isomorphic/enums/answers";
import type {JSONObject, JSONQuestion, JSONValue} from "pg-isomorphic/utils";
import type {ValidationType} from "pg-isomorphic/enums/question";
import type {ConnectionRole, GroupType} from "pg-isomorphic/enums";
import {QuestionType} from "pg-isomorphic/enums";
import type {ComputeProps, Question} from "pg-isomorphic/props";
import type {ComputedRef, Ref} from "vue";
import type {MessageCount} from "pg-isomorphic/api/message";
import type {ApprovalCount} from "pg-isomorphic/api/approval";
import type {NoteCount} from "pg-isomorphic/api/note";
import type {Task, TaskCount} from "pg-isomorphic/api/tasks";
import type {ReminderCount} from "pg-isomorphic/api/reminder";
import type {TwoFaNonProfileSection, UserAvatar} from "pg-isomorphic/api/user";
import type {ExtendedEntityInfo} from "pg-isomorphic/api/entity";
import type {Answers, ConnectionOrKitContext, ConnectionWithNames} from "pg-isomorphic/api/connection";
import type {TranslateUIEventTypes} from "pg-isomorphic/enums/translate";
import type {ChangeItem} from "@/composables/questions/defaults";
import type {BasicOption, QuestionOption} from "pg-isomorphic/options";
import type SmartyStreets from "smartystreets-javascript-sdk";

export interface UploadFile {
  fileId: string;
  mimeType: string;
  filename: string;
  fileName: string;
  hasThumbnail: boolean;
}

export type SelectAnswer =
  | {
      label: string;
      value: string;
    }
  | string;

export interface SectionOption {
  value: string;
}

export interface UserOptionsGroup {
  group: string;
  groupOptions: UserOption[];
}

export interface UserInputOptions {
  questionData: JSONQuestion;
  suppressEmailAppend?: boolean;
  connectionOrKitContext?: ConnectionOrKitContext;
  answers?: Answers;
  isContact?: boolean;
  searchText?: string;
  nonNetworkEntityId?: string;
  defaultUserIds?: string[];
}

export interface UserOption extends BasicOption {
  value: string;
  name: string;
  email: string;
  image: string;
  hideAvatar?: boolean;
  identityVerified?: boolean;
}

export enum AnswerFormats {
  TEXT = 100,
  TEXT_LOCALE,
  NUMBER,
  SELECTED_OPTIONS,
  ARRAY_OF_STRINGS,
  FILE_OBJECT,
  TAGS,
  DATE,
  BOOLEAN,
  CERTIFY,
  ADDRESS,
  ROUTING,
  SAM_GOV,
  CONTACT,
  ASSOCIATED_KIT_TABLE,
  NONE, // This means it will be handled by the input component (because it's unique, does not have an answer, or maybe doesn't make sense to split edit / view component
}

// This probably shouldn't be here
export interface TwoFaOptions {
  onCancel?: () => void;
  onVerified?: () => void;
}

export interface TwoFaProfileOptions extends TwoFaOptions {
  connectionId: string;
  currentProfile: string;
}

export interface TwoFaNonProfileOptions extends TwoFaOptions {
  section: TwoFaNonProfileSection;
}

export interface TwoFaProfileCheckInput {
  // Pass in a question.
  question?: JSONQuestion;
  editMode: boolean;
  // Or pass in security roles.
  securityRoles?: string[];
  // Or pass in instanceKeys and serverSideTopicIds.
  instanceKeys?: string[];
  serverSideTopicIds?: string[];
}

export enum TwoFaInputMode {
  NON_PROFILE = "non_profile",
  QUESTION = "question",
  SECURITY_ROLES = "security_roles",
  INSTANCE_KEYS = "instance_keys",
}

export type CustomValidationCheck = () => boolean;

export interface InputOverrides {
  additionalValidationChecks: CustomValidationCheckOptions[];
  connectionId: string;
}

export interface CertifyAnswerMetadata {
  lastCertified: string;
  lastCertifiedBy?: string;
}

export interface CertifyAnswer {
  certified?: string;
  metaData?: CertifyAnswerMetadata;
}

export interface AddressComponents {
  [Address.Line1]?: string;
  [Address.Line2]?: string;
  [Address.City]?: string;
  [Address.RegionState]?: string;
  [Address.Subdivision]?: string;
  [Address.SubSubdivision]?: string;
  [Address.ISOCode]?: string;
  [Address.PostalCode]?: string;
  [Address.Country]?: string;
}

export interface SmartyIntData extends AddressComponents {
  data?: Partial<SmartyStreets.internationalStreet.Candidate>;
}

export interface SmartyIntResults {
  native?: SmartyIntData;
}

export type SmartyUSResults = Partial<SmartyStreets.usStreet.Candidate>;

export type SmartyResults = SmartyIntResults & SmartyUSResults;

export interface AddressAnswer extends AddressComponents {
  data?: SmartyResults;
  validationType: string;
  validated: boolean;
  complete: boolean;
  autoSelected: boolean; // for backwards compatability
  forceSupplied: boolean; // for backwards compatability
  valid: boolean;
}

export interface RoutingAnswer {
  bank_name?: string;
  swift?: string;
  type?: string;
  routing?: string;
  valid: boolean;
  bank?: JSONObject;
  fromDatabase: boolean;
  error?: JSONValue;
}

export interface FileInputData {
  fileName: string;
  fileSize: number;
}

export interface ValidationRules {
  type: ValidationType;
  optional?: boolean;
  empty?: boolean;
  convert?: boolean;
  passScore?: number;
  passMin?: number;
  matchValue?: (() => string) | string;
  messages?: {[key: string]: string};
  min?: number;
  max?: number;
  length?: number;
}

export interface ValidationObject {
  mask?: string | string[] | ((value: string) => string);
  rules?: ValidationRules;
}

export type QuestionData = Question & {
  _id?: string;
  disabledChangeEvent?: boolean;
  password?: boolean;
  validation?: ValidationObject;
  list?: ListConfig;
  options?: QuestionOption[];
  allowEmpty?: boolean;
  minNumRows?: number;
  placeholderText?: string;
  hideLabel?: boolean;
  skipLocalization?: boolean;
  enableAnswerTranslation?: boolean;
  forceEditMode?: boolean;
};

export interface ValidationErrorObject {
  type?: string;
  expected?: any;
  actual?: any;
  field?: string;
  message?: string;
  makePassesNull?: boolean;
  alreadyTranslated?: boolean;
}

export type ValidationCheckResults = boolean | ValidationErrorObject[];

export interface ValidationCheckValueArg {
  value: any;
}

export type ValidationCheck = (validationCheckValue: ValidationCheckValueArg) => ValidationErrorObject[];
export type CustomValidationCheckMethod = (questionData: JSONQuestion, value: any) => ValidationCheckResults;

export interface ValidationSummary {
  passes: boolean;
  details: Partial<ValidationErrorObject>;
  forceUpdate?: boolean;
}

export interface CustomValidationCheckOptions {
  validationKey: string; // Unique string to describe validation for caching and debugging
  onlyRunIfModified?: boolean; // True by default
  skipIfValueIsNull?: boolean; // True by default
  validationCheckHandler: CustomValidationCheckMethod;
  addValidationCondition: (questionData: JSONQuestion) => boolean;
}

export const readOnlyInputTypes: (QuestionType | GroupType)[] = [
  QuestionType.DIVIDER,
  QuestionType.INFO,
  QuestionType.STOP,
  QuestionType.WARNING,
];

export interface QuestionOrderGroup {
  key: string;
  visible: boolean;
  sortable: boolean;
  order: string;
}

export interface CustomAction {
  label: string;
  bulkOperation: boolean;
}

export interface QuestionOrderGroup {
  key: string;
  visible: boolean;
  sortable: boolean;
  order: string;
  type: string;
}

export interface CustomAction {
  label: string;
  bulkOperation: boolean;
}

export interface EditState {
  editKey: string;
  isEditing: boolean;
}

export type TopicStates = string[];

export interface InstanceStates {
  [tableId: string]: string;
} // An array of topic keys
export type InstanceShownStates = string[]; // An array of topic keys

export type ChangeEditStateHandler = (key: string, state: boolean) => void;

export type ChangeTopicStateHandler = (key: string, state: boolean) => void;

export type ChangeInstanceShownStateHandler = (instanceNs: string, state: boolean) => void;

export type ChangeInstanceStateHandler = (tableId: string, instanceNs: string, state: boolean) => void;

export type ChangeInstanceEditStateHandler = (editRowInstanceId: string, state: boolean) => void;

export type ChangeTab = (tabKey: string) => void;

export type CheckEditStateMethod = (key: Ref<string>, elementData?: Ref<JSONQuestion>) => ComputedRef<boolean>;

export type AddInstanceMethod = (groupKey: string, parentInstanceId?: string) => Promise<string>;

export type RemoveInstanceMethod = (...instanceKeys: string[]) => Promise<void>;

export type CheckTopicStateMethod = (key: string) => boolean;

export type ChangeAnswerHandler = (questionData: JSONQuestion, answer: any) => Promise<void>;

export type RemoveAnswerHandler = (questionData: JSONQuestion) => void;

export type TranslationEventHandler = (
  type: TranslateUIEventTypes,
  goodTranslation: boolean,
  sourceLocale: string,
  elementKey?: string,
) => Promise<TranslationResult>;

export interface GoToElement {
  instanceId?: string;
  elementId: string;
  expandReview?: boolean;
  disableScrollTo?: boolean;
  containerId?: string;
}

export interface QuestionInfoPaneData {
  container?: string; // used to manage scroll events
  editMode?: boolean;
  elementId: string;
  data: any; // JSONObject (task info)
}

export interface RecursiveQuestionProps {
  hide_actions: boolean;
  checkboxMode: boolean;
  checkboxValueKey: string;
  checkboxModeAllChecked: boolean;
  showOnlyRows: boolean;
  overrideVisibility: boolean;
  tableCheckedValue: string[];
  parentInstanceId: string;
  editRowInstanceId: string;
  overrideToReadonly: boolean;
  overrideToHideTools: boolean;
}

export interface QuestionTreeCounts {
  messages: MessageCount[];
  approvals: ApprovalCount[];
  notes: NoteCount[];
  tasks: TaskCount[];
  reminders: ReminderCount[];
  changedSinceLastReview?: any[];
}

export interface UpdateQuestionTreeCountsAddParams {
  upcoming?: number;
  incomplete?: number;
  completed?: number;
}

export enum QuestionTreeCountTypes {
  TASKS = "tasks",
  MESSAGES = "messages",
  NOTES = "notes",
  REMINDERS = "reminders",
  APPROVALS = "approvals",
}

export const DEBOUNCE_TYPES = [
  QuestionType.TEXT,
  QuestionType.MULTI_TEXT,
  QuestionType.ROUTING,
  QuestionType.SAM_GOV,
  QuestionType.TEXTAREA,
  QuestionType.MULTI_LANGUAGE_TEXT,
];
export const DEBOUNCE_WAIT = 600;

// Standard actions that need to be handled in different places. Could be expanded in future.
export enum ActionStandardHandler {
  EDIT = 1,
  // NOTES,
  // HISTORY,
  // REMINDER,
  // MESSAGE,
}

export interface ElementAction<T = any> {
  icon: string[];
  text: string;
  class?: string;
  clickHandler?: (row?: T) => void;
  standardClickHandler?: ActionStandardHandler;
  showDot?: boolean;
}

export enum ElementActionsShow {
  DO_NOT_SHOW = 1, // Do not show the ellipsis
  SHOW, // Means you should show when appropriate (on mouse over question for example)
  ALWAYS_SHOW, // Always show regardless of mouse over
}

export interface RefPopData {
  groupKey: string;
  instanceId: string;
}

/**
 * This data is just stuff we calculate in some of our components and is known to be used to help generate actions.
 * Rather than recalculate this we just pass it a long as an option. It's a little specific to connections but completely
 * options. It's a compromise people!
 */
export interface AdditionalActionsData {
  hasOwnQuestions?: boolean;
  comprisedOfReadOnly?: boolean;
  inEditMode?: boolean;
  instanceId?: string;
  entityId?: string;
  theirEntityId?: string;
  entityName?: string;
  logoId?: string;
  connectionRole?: ConnectionRole;
  connectionId?: string;
  tasks?: Task[];
  editRules?: EditRules;
  is2faUnlocked?: boolean;
  instanceData?: JSONQuestion;
  isOnConnectionOverviewTab?: boolean;
  isOnReferenceDrawer?: boolean;
}
export interface AdditionalActionsDataAsRefs {
  hasOwnQuestions?: Ref<boolean>;
  comprisedOfReadOnly?: Ref<boolean>;
  inEditMode?: Ref<boolean>;
  instanceId?: Ref<string>;
  entityId?: Ref<string>;
  theirEntityId?: Ref<string>;
  entityName?: Ref<string>;
  logoId?: Ref<string>;
  connectionRole?: Ref<ConnectionRole>;
  connectionId?: Ref<string>;
  tasks?: Ref<Task[]>;
  editRules?: Ref<EditRules>;
  is2faUnlocked?: Ref<boolean>;
}

/**
 * Eventually this will handle most of the click handlers for the different actions
 */
export interface ActionHandlers {
  history?: () => void;
  tasks?: () => void;
}

export interface OptionalElementDataArgs {
  hasOwnQuestions?: boolean;
  comprisedOfReadOnly?: boolean;
  connectionId?: string;
  editRules?: EditRules;
}

export type showElementActionsMethod = (elementData: JSONQuestion) => ElementActionsShow;
export type generateElementActionsMethod = (
  elementData: JSONQuestion,
  additionalData?: AdditionalActionsData,
) => ElementAction[];

export type CancelHandler = () => Promise<void>;
export type SaveHandler = (
  editNs?: string,
  skipSleepDelay?: boolean,
  saveType?: SaveType,
  associatedTopicTask?: any,
) => void;
export type SaveSingleAnswerHandler = (answerKey: string) => Promise<void>;

export interface OverrideEditStateFunctionality {
  cancelHandler: CancelHandler;
  saveHandler: SaveHandler;
  isInEditState: boolean;
}

export enum EditStateScope {
  TOPIC = 1,
  ENTIRE_TREE,
}

export interface ValidationState {
  state: boolean;
  message: string;
  questionKey: string;
}

export interface QuestionTreeSectionSpecificData {
  associatedTasks?: Task[];
  userEntityInfo?: ExtendedEntityInfo;
  counterPartyEntityInfo?: ExtendedEntityInfo;
  enableTopicReviewChangeAnswerGet?: boolean;
  counterPartyUsers?: {[key: string]: UserAvatar};
  connectionRecord?: ConnectionWithNames;
  kitRecord?: ConnectionOrKitContext;
  nsjDrawer?: {
    deleteConnection?: () => void;
    hideHeader?: () => void;
    showHeader?: () => void;
  };
  kitDetail?: {
    kitTaskProcessing: boolean;
  };
}

export interface questionDataSet {
  originalQuestionData: JSONQuestion;
  originalAnswers: Answers;
  initializedQuestionData: Ref<JSONQuestion>;
  initializedAnswers: Ref<Answers>;
  filteredQuestionData: Ref<JSONQuestion>;
  computeProps: ComputeProps;
}

export interface InitializedQuestionDataSet {
  originalQuestionData: JSONQuestion;
  initializedQuestionData: JSONQuestion;
  computeProps: ComputeProps;
}

export enum EditRules {
  CAN_ONLY_EDIT_STANDARD_QUESTIONS = 1,
  CAN_ONLY_EDIT_INTERNAL_USE,
  CAN_EDIT_INTERNAL_USE_AND_STANDARD,
  CAN_NOT_EDIT,
}

export interface NavigateToElementReturnData {
  element: JSONQuestion;
  parentTopic: JSONQuestion;
  parentSubtopic: JSONQuestion;
  parentTab: JSONQuestion;
  parentGroupItem: JSONQuestion;
  parentGroupTable: JSONQuestion;
  parentReviewTable: JSONQuestion;
  instanceId: string;
  error: string;
}

export interface GetOptionsTreeData {
  questions: Ref<JSONQuestion>;
  answers: Ref<Answers>;
  counter?: {
    questions: Ref<JSONQuestion>;
    answers: Ref<Answers>;
  };
}

export enum RefreshQuestionTreeReason {
  REVIEW_COMPLETED = 1,
  IS_GRAPHITE_NETWORK_SUPPLIER,
}

export enum RefreshQuestionTreeSide {
  BOTH = 1,
  CURRENT,
}

export interface TranslateEventData {
  type: TranslateUIEventTypes;
  sourceLocale: string;
  goodTranslation: boolean;
}

export interface DefaultInitializedResponse {
  changes: ChangeItem[];
  openDialog: boolean;
}

export interface LeftNavRequiredCount {
  completed: boolean;
  requiredCount: number;
  hideIfCompleted: boolean;
}

export type LeftNavRequiredCounts = {[key: string]: LeftNavRequiredCount};
