import {getStore} from "@/composables/get.store";
import globalLogger from "@/logging";
import {nonDefaultInstances} from "pg-isomorphic/profile/group-util";
import {ExpressionEvaluator} from "pg-isomorphic/rules/expression";
import {INSTANCE_ORDER} from "pg-isomorphic/utils/constants";
import type {ComputedRef, Ref} from "vue";
import {computed, ref} from "vue";
import type {Answers} from "pg-isomorphic/api/connection";
import {MagicAnswerKeys} from "pg-isomorphic/enums/answers";
import {ActionType} from "pg-isomorphic/enums/trigger";
import {
  assocPath,
  endsWith,
  equals,
  findIndex,
  has,
  head,
  includes,
  is,
  isNil,
  last,
  path,
  pathOr,
  slice,
  union,
} from "ramda";
import type {JSONObject, JSONQuestion} from "pg-isomorphic/utils";
import {coerceToArray} from "pg-isomorphic/utils";
import type {GroupType, QuestionType} from "pg-isomorphic/enums";
import {Internal, ValidationStatus} from "pg-isomorphic/enums";
import type {ChangeAnswerHandler} from "@/composables/questions/types";
import {DEBOUNCE_TYPES, DEBOUNCE_WAIT, EditRules} from "@/composables/questions/types";
import debounce from "lodash/debounce";
import {globalEmit} from "@/composables/globalEventBus";
import {processTreeTopDown} from "pg-isomorphic/profile";
import {ProfileGroup} from "pg-isomorphic/profile/group";
import type {ComputeProps} from "pg-isomorphic/props";
import type {KitType} from "pg-isomorphic/enums/element";
import {httpPatch} from "@/composables/xhr";
import {handleXhrError} from "@/composables/errorHandling";
import {RegisteredErrors} from "@/composables/errorHandling/registeredErrors";
import {processTreeCompoundKey} from "pg-isomorphic/answers";
import {isFreeTextAnswer} from "pg-isomorphic/answers/values";

const logger = globalLogger.getLogger("answers");

export type GroupInstanceChangeHandler = (key: string) => void;

/**
 * Actually updates the answer object
 *
 * @param key -- full key (for groups) or single key
 * @param value
 * @param answers -- full answer object or a relative object within (specific group instance)
 * @param groupParentage -- Provided when a relative object is given, so we still can assemble the full key
 * @param cb
 */
export function answerSet(key, value, answers: Answers, groupParentage?: string, cb?: GroupInstanceChangeHandler) {
  // logger.trace(() => `answerVueSet ${key}: ${value}`);
  const keySplit = key.split(".");
  let answerSaveLocation;
  if (keySplit.length === 1) {
    answerSaveLocation = answers;
  } else if (keySplit.length === 2) {
    answerSaveLocation = path(slice<string>(0, keySplit.length - 1, keySplit), answers);
    if (answerSaveLocation === undefined) {
      logger.error(`answerVueSet ignoring unknown path: ${key}`);
      return;
    }
  } else {
    answerSaveLocation = path(slice<string>(0, keySplit.length - 1, keySplit), answers);
    if (answerSaveLocation === undefined) {
      // This is most likely creating a new group instance.
      const secondChance: any = path(slice<string>(0, keySplit.length - 2, keySplit), answers);
      if (secondChance === undefined) {
        logger.error(`answerVueSet ignoring two levels of unknown path: ${key}`);
        return;
      }
      logger.info(`answerVueSet creating second level path for: ${key}`);
      secondChance[keySplit[keySplit.length - 2]] = {};
      if (cb) {
        cb(key);
      }
      answerSaveLocation = path(slice<string>(0, keySplit.length - 1, keySplit), answers);
    }
    if (cb && keySplit[keySplit.length - 1] === Internal.DELETED) {
      if (answerSaveLocation[keySplit[keySplit.length - 1]] !== value) {
        // Group instance was soft-deleted.
        cb(key);
      }
    }
  }
  answerSaveLocation[keySplit[keySplit.length - 1]] = value;
  // Emit global even for answer change
  globalEmit("answer:answerChanged", {
    key: groupParentage ? `${groupParentage}.${key}` : key,
    value: value,
  });
}

export function answerVueDelete(key, answers: Answers) {
  const keySplit = key.split(".");
  const answerSaveLocation = pathOr(answers, slice<string>(0, keySplit.length - 1, keySplit), answers);
  delete answerSaveLocation[keySplit[keySplit.length - 1]];
}

export function setAnswerDiff(newAnswers: Answers, oldAnswers: Answers, groupParentage?: string) {
  // console.log("setAnswerDiff", newAnswers, oldAnswers);
  let checkInstanceOrder = false;
  for (const newAnswerKey of Object.keys(newAnswers)) {
    const newAnswerValue = newAnswers[newAnswerKey];
    const oldAnswerValue = oldAnswers[newAnswerKey];
    if (newAnswerKey === INSTANCE_ORDER) {
      checkInstanceOrder = true;
      continue;
    }
    if (!has(newAnswerKey, oldAnswers)) {
      // logger.trace(() => `new answer: ${newAnswerKey}`);
      answerSet(newAnswerKey, newAnswerValue, oldAnswers, groupParentage);
    } else if (is(Object, newAnswerValue) && !is(Array, newAnswerValue) && !isFreeTextAnswer(newAnswerValue)) {
      if (oldAnswerValue) {
        let newGroupParentage = groupParentage;
        if (newAnswerValue[INSTANCE_ORDER]) {
          newGroupParentage = newAnswerKey;
        } else if (groupParentage) {
          newGroupParentage += `.${newAnswerKey}`;
        }
        setAnswerDiff(newAnswerValue, oldAnswerValue, newGroupParentage);
      } else {
        // logger.trace(() => `is object: ${newAnswerKey}`, newAnswerValue, oldAnswerValue);
        answerSet(newAnswerKey, newAnswerValue, oldAnswers, groupParentage);
      }
    } else if (!equals(newAnswerValue, oldAnswerValue)) {
      // logger.trace(() => `not equal: ${newAnswerKey}`, newAnswerValue, oldAnswerValue);
      answerSet(newAnswerKey, newAnswerValue, oldAnswers, groupParentage);
    }
  }
  for (const oldAnswerKey of Object.keys(oldAnswers)) {
    if (!has(oldAnswerKey, newAnswers)) {
      delete oldAnswers[oldAnswerKey];
    }
  }
  // Do instance order last, just in case because some UI stuff might need it this way
  if (checkInstanceOrder && !equals(newAnswers[INSTANCE_ORDER], oldAnswers[INSTANCE_ORDER])) {
    answerSet(INSTANCE_ORDER, newAnswers[INSTANCE_ORDER], oldAnswers);
  }
}

const debounceSetAnswersDiff = debounce((answers, theirAnswers, name, computeProps) => {
  setAnswerDiff(computeProps.transform(answers, theirAnswers, name), answers);
}, DEBOUNCE_WAIT);

export function getValueFromAnswerUsingKey(answers: Answers, key: string) {
  const nameSplit = key.split(".");
  if (nameSplit.length > 1) {
    return pathOr(null, nameSplit, answers);
  }
  return answers[key];
}

async function computePropsAndDiff(
  computeProps: ComputeProps,
  answers: Ref<Answers>,
  theirAnswers: Ref<Answers> = ref({}),
  questionType?: QuestionType | GroupType,
  changedAnswerKey?: string,
) {
  if (questionType && DEBOUNCE_TYPES.indexOf(questionType as QuestionType) > -1) {
    debounceSetAnswersDiff(answers.value, theirAnswers.value, changedAnswerKey, computeProps);
  } else {
    setAnswerDiff(await computeProps.transform(answers.value, theirAnswers.value, changedAnswerKey), answers.value);
  }
}

export async function updateAnswers(
  updated: JSONObject,
  computeProps: ComputeProps,
  answers: Ref<Answers>,
  theirAnswers: Ref<Answers> = ref({}),
  cb?: any,
) {
  processTreeCompoundKey(updated, (compoundKey: string, value: any) => {
    answerSet(compoundKey, value, answers.value, null, cb);
    globalEmit("answer:answerChanged", {key: compoundKey, value});
  });
  await computePropsAndDiff(computeProps, answers, theirAnswers);
}

export async function updateAnswerForQuestion(
  questionData: JSONQuestion,
  value: any,
  answers: Ref<Answers>,
  computeProps: ComputeProps,
  theirAnswers: Ref<Answers> = ref({}),
) {
  answerSet(questionData.key, value, answers.value);
  setAnswerDiff(await computeProps.transform(answers.value, theirAnswers.value, questionData.key), answers.value);
  globalEmit("answer:answerChanged", {
    key: questionData.key,
    value: value,
  });
}

export function makeInstancePrimary(instanceKey: string, questionData: JSONQuestion, answers: Answers) {
  const [groupKey, instanceId] = instanceKey.split(".");
  ProfileGroup.makeInstancePrimary(groupKey, instanceId, {
    questions: questionData,
    answers: answers.value,
  });

  return {};
}

/**
 * Will take the existing answers and changed answers (from a form save) and give back only the changed answers
 * @param questionTree
 * @param originalAnswers
 * @param updatedAnswers
 * @param editRules
 */
export function getChangedAnswers(
  questionTree: JSONQuestion,
  originalAnswers: Answers,
  updatedAnswers: Answers,
  editRules: EditRules,
) {
  let saveData = {};
  let externalValidationAnswerUpdates = {};
  let questionDataMappedByAnswerKey = {};

  const questionMap = {};
  processTreeTopDown((q: JSONQuestion) => {
    if (!questionMap[q.key]) {
      questionMap[q.key] = [];
    }
    questionMap[q.key].push(q);
  })(questionTree);

  const getQuestionByKey = (key): JSONQuestion => {
    const byKey = questionMap[key] || [];
    return (
      byKey.find((q) => (editRules === EditRules.CAN_ONLY_EDIT_STANDARD_QUESTIONS ? true : q.internalUse)) ||
      head(byKey)
    );
  };

  const appendSaveData = (
    element: JSONQuestion,
    key: string,
    existingAnswerValue: any,
    newAnswerValue: any,
    saveData: Answers,
    path: string[],
  ) => {
    // Do a number of checks to see if we can save this.
    // We will not save this if:
    // - answer did not change OR
    // - question is secured but user is not 2fa-auth'd OR
    // - it's a non-question validation status OR
    // - it's a calculation OR
    // - the edit rules logic is basically:
    //   - EditRules.CAN_ONLY_EDIT_STANDARD_QUESTIONS: edit normal or shared question but NOT internal use
    //   - EditRules.CAN_ONLY_EDIT_INTERNAL_USE: edit internal use or shared question but NOT normal
    //   - EditRules.CAN_EDIT_INTERNAL_USE_AND_STANDARD: edit any question
    if (
      equals(newAnswerValue, existingAnswerValue) ||
      (pathOr(false, ["secured"], element) && !getStore().state.twoFactor.verifiedForQuestions) ||
      (!element && endsWith(Internal.VALIDATION_STATUS, key)) ||
      element?.calculation ||
      (editRules === EditRules.CAN_ONLY_EDIT_STANDARD_QUESTIONS && element?.internalUse) ||
      (editRules === EditRules.CAN_ONLY_EDIT_INTERNAL_USE &&
        !element?.internalUse &&
        !element?.counterpartyCanEditAnswer)
    ) {
      if (!element && key.endsWith(`.${Internal.DELETED}`) && !equals(newAnswerValue, existingAnswerValue)) {
        // Allow deletion of Internal Use instances.
        // Already deleted instances come here also, so we must check that the value has changed.
      } else {
        // Skip this data.
        return saveData;
      }
    }

    if (pathOr(false, ["externalValidation"], element)) {
      externalValidationAnswerUpdates[`${element.key}${Internal.VALIDATION_STATUS}`] = ValidationStatus.PENDING;
    }

    questionDataMappedByAnswerKey[key] = getQuestionByKey(key);
    return assocPath(path, newAnswerValue, saveData);
  };

  // Go through each answer (original and otherwise)
  for (const currentName of union(Object.keys(originalAnswers), Object.keys(updatedAnswers))) {
    const originalAnswer = path([currentName], originalAnswers);
    const currentValue = path([currentName], updatedAnswers);

    if (currentValue === undefined) {
      continue;
    }

    // If this is a group go down this path, else it's not in a group
    if (is(Object, currentValue) && is(Array, currentValue[INSTANCE_ORDER])) {
      // Ignore default instances. They're not real.
      const workingInstanceOrder = nonDefaultInstances(currentValue);

      // Null Removed Instances
      const cachedInstanceOrder = nonDefaultInstances(originalAnswer);
      for (const cachedInstance of cachedInstanceOrder) {
        if (findIndex(equals(cachedInstance), workingInstanceOrder) === -1) {
          saveData = assocPath([currentName, cachedInstance], null, saveData);
        }
      }

      // Fill In New / Changed Instances
      for (const currentInstance of workingInstanceOrder) {
        const currentInstanceValues = currentValue[currentInstance];
        // Check in cachedInstanceOrder, so default instances in the cached answers are ignored
        let cachedInstanceValue = includes(currentInstance, cachedInstanceOrder)
          ? originalAnswer[currentInstance]
          : undefined;
        if (isNil(cachedInstanceValue)) {
          // Not cached, then nothing to send
          cachedInstanceValue = {};
        }
        for (const currentGroupKey of union(Object.keys(cachedInstanceValue), Object.keys(currentInstanceValues))) {
          const currentGroupValue = currentInstanceValues[currentGroupKey];
          const currentCachedValue = cachedInstanceValue[currentGroupKey];
          const currentKey = [currentName, currentInstance, currentGroupKey].join(".");
          const groupQuestion = getQuestionByKey(currentKey);

          saveData = appendSaveData(groupQuestion, currentKey, currentCachedValue, currentGroupValue, saveData, [
            currentName,
            currentInstance,
            currentGroupKey,
          ]);
        }
      }
      // If any changes recorded, include the instance order
      const orderChanged = !equals(workingInstanceOrder, cachedInstanceOrder);
      if (saveData[currentName] !== undefined || orderChanged) {
        saveData = assocPath([currentName, INSTANCE_ORDER], workingInstanceOrder, saveData);
      }
    } else {
      const question = getQuestionByKey(currentName);
      saveData = appendSaveData(question, currentName, originalAnswer, currentValue, saveData, [currentName]);
    }
  }

  return {saveData, externalValidationAnswerUpdates, questionDataMappedByAnswerKey};
}

export function revertAnswersEmitNotices(originalAnswers: Answers, answers: Ref<Answers>) {
  for (const key of Object.keys(originalAnswers)) {
    const originalAnswer = originalAnswers[key];
    const answer = answers.value[key];
    if (!equals(originalAnswer, answer)) {
      globalEmit("answer:answerReverted", {key, value: originalAnswer});
    }
  }

  return answers;
}

export function getAnswerUsingChildrenIndex(
  answers: Answers,
  children: JSONQuestion[],
  childIndex: number,
  defaultValue?: any,
  subPath?: string,
) {
  const childData = pathOr(defaultValue, [childIndex], children);
  if (!childData) {
    return null;
  }
  let keyPath = childData.key.split(".");
  if (subPath) {
    keyPath = [...childData.key.split("."), ...subPath.split(".")];
  }
  return pathOr(defaultValue, [...keyPath], answers);
}

export function getChildIndexByKey(children: JSONQuestion[], key: string) {
  return findIndex((kid) => key === last(kid.key.split(".")), children);
}

export async function doElementAction(
  action,
  element: JSONQuestion,
  instances: string[],
  answers: Answers,
  changeAnswer: ChangeAnswerHandler,
) {
  switch (action.type) {
    case ActionType.EDIT:
      await doEditAction(action, element, instances, answers, changeAnswer);
      break;
    default:
      break;
  }
}

async function doEditAction(
  action,
  element: JSONQuestion,
  instances: string[],
  answers: Answers,
  changeAnswer: ChangeAnswerHandler,
) {
  const editAction = action.action;
  const instanceIdExp = new ExpressionEvaluator(editAction.instanceId || "new");
  for (const instance of instances) {
    const instanceAnswers = pathOr({}, [element.key, instance], answers);
    const keyPrefix = [];
    if (editAction.groupKey) {
      keyPrefix.push(editAction.groupKey);
      keyPrefix.push(instanceIdExp.evaluate(answers, instanceAnswers, {[MagicAnswerKeys.INSTANCE_ID]: instance}));
    }

    for (const key of Object.keys(editAction.data)) {
      const value = new ExpressionEvaluator(editAction.data[key]).evaluate(answers, instanceAnswers, {
        [MagicAnswerKeys.INSTANCE_ID]: instance,
      });
      const name = [...keyPrefix, key].join(".");
      const fakeQuestion: Partial<JSONQuestion> = {
        key: name,
        name,
      };
      await changeAnswer(fakeQuestion as JSONQuestion, value);
    }
  }
}

export async function patchKit(
  kitType: KitType,
  kitId: string | undefined,
  answers: JSONObject,
  owningEntityId: string,
  socketId: string,
): Promise<{newKeys?: JSONObject; kitId?: string} | undefined> {
  try {
    const response = await httpPatch<{newKeys?: JSONObject; kitId?: string}>(
      `/api/answers/kit/${kitType}/${kitId || "new"}`,
      {...answers, owningEntityId, socketId},
    );
    return response.data;
  } catch (e) {
    handleXhrError(RegisteredErrors.SAVE_KIT, `Could not save kit ${kitType}`, e);
  }
}

export function getGetAnswerComputedFunction(
  answers: Ref<JSONObject>,
): (answerQuestionData: Ref<JSONQuestion>, defaultAnswer?: any) => ComputedRef<any> {
  return (answerQuestionData: Ref<JSONQuestion>, defaultAnswer: any) => {
    return computed(() => {
      const defaultValue = answerQuestionData.value.type === "checkbox" ? [] : null;
      let answerValue = pathOr(
        defaultAnswer || defaultValue,
        [...answerQuestionData.value.key.split(".")],
        answers.value,
      );
      if (is(Array, defaultValue) && !is(Array, answerValue) && !answerQuestionData.value.secured) {
        return coerceToArray(answerValue);
      }
      return answerValue;
    });
  };
}

export function getGetAnswerComputedFunctionUsingRawValues(
  answers: Ref<JSONObject>,
): (answerQuestionData: Ref<JSONQuestion>, defaultAnswer?: any) => ComputedRef<any> {
  return (answerQuestionData: Ref<JSONQuestion>, defaultAnswer: any) => {
    return computed(() => {
      const defaultValue = answerQuestionData.value.type === "checkbox" ? [] : null;
      let answerValue = pathOr(
        defaultAnswer || defaultValue,
        [...answerQuestionData.value.key.split(".")],
        answers.value,
      );
      if (is(Array, defaultValue) && !is(Array, answerValue) && !answerQuestionData.value.secured) {
        return coerceToArray(answerValue);
      }
      return answerValue;
    });
  };
}
