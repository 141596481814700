import cloneDeep from "lodash/cloneDeep";
import type {Ref, WritableComputedRef} from "vue";
import {computed, ref} from "vue";
import type {ListUser} from "pg-isomorphic/api/user";

const namespace = "GRAPHITE";

export enum StorageKey {
  CONTACT_OPTIONS = "contact_options",
  USER_OPTIONS = "user_options",
  CONNECTION_SEARCH_LIMIT = "connection_search_limit",
}

export type StorageItem<T> = WritableComputedRef<T> & {reset(): void};

type EntityId = string;
export type ContactOptionsByEntityStorageKey = `${typeof StorageKey.CONTACT_OPTIONS}_${EntityId}`;

export type StorageTypes = {
  [StorageKey.USER_OPTIONS]: unknown;
  [StorageKey.CONNECTION_SEARCH_LIMIT]: number;
} & Record<ContactOptionsByEntityStorageKey, ListUser[]>;

type StorageKeyExtended = keyof StorageTypes | ContactOptionsByEntityStorageKey;

export function storageComputed<K extends StorageKeyExtended>(
  key: K,
  defaultVal: StorageTypes[K],
): StorageItem<StorageTypes[K]>;
export function storageComputed<K extends StorageKeyExtended>(key: K): StorageItem<StorageTypes[K] | null>;
export function storageComputed<K extends StorageKeyExtended>(
  key: K,
  defaultVal?: StorageTypes[K],
): StorageItem<StorageTypes[K] | null> {
  let initialValue = getFromStorage<K>(key);
  if (initialValue === null && defaultVal !== undefined) {
    initialValue = defaultVal;
  }
  const internalVal: Ref<StorageTypes[K] | null> = ref(cloneDeep(initialValue)) as Ref<StorageTypes[K] | null>;

  const item = computed<StorageTypes[K] | null>({
    get(): StorageTypes[K] | null {
      return internalVal.value;
    },
    set(val: StorageTypes[K] | null) {
      internalVal.value = val;
      saveInStorage(key, val);
    },
  }) as StorageItem<StorageTypes[K] | null>;

  item.reset = () => {
    removeFromStorage(key);
    internalVal.value = cloneDeep(defaultVal || null);
  };

  return item;
}

export function getFromStorage<K extends StorageKeyExtended>(key: K): StorageTypes[K] | null {
  const val = localStorage.getItem(`${namespace}::${key}`);
  if (!val) {
    return null;
  }

  try {
    return JSON.parse(val);
  } catch {
    return null;
  }
}

export function saveInStorage<K extends StorageKeyExtended>(key: K, val: StorageTypes[K] | null): void {
  if (val === null) {
    removeFromStorage(key);
  } else {
    localStorage.setItem(`${namespace}::${key}`, JSON.stringify(val));
  }
}

export function removeFromStorage<K extends StorageKeyExtended>(key: K): void {
  localStorage.removeItem(`${namespace}::${key}`);
}

export function clearStorage() {
  localStorage.clear();
}
