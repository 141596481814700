export enum RegisteredErrors {
  GLOBAL,
  BVD_DATA,
  SMART_CUBE_DATA,
  ECO_VADIS,
  QUESTION_HISTORY,
  DARKBEAM_DATA,
  DUNBRADSTREET_DATA,
  USERS_FOR_ROLE,
  GET_USERS,
  ADD_USER,
  CHECK_USER_EMAIL,
  GET_USER_ROLES,
  GET_CONNECTION_USER,
  GET_CONNECTION_PDF,
  GET_COUNTERPARTY_USERS,
  DELETE_USER_FROM_ROLE,
  ADD_USER_TO_ROLE,
  SIGN_UP_ROLES,
  INVITE_USER,
  UPDATE_USER,
  NETSUITE_ADMIN,
  TOPIC_TREE,
  RISKRECON_DATA,
  VALIDATION_LOG,
  // CAMPAIGNS
  GET_CAMPAIGN,
  GET_CAMPAIGNS,
  GET_CAMPAIGN_COUNT,
  ADD_CAMPAIGN,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  INVITE_TO_CAMPAIGNS,
  //ENTITIES
  GET_ENTITY,
  GET_ENTITIES,
  GET_ENTITY_USER_COUNTS,
  GET_ENTITY_ANSWERS,
  // RISK OVERRIDES
  SET_OVERRIDES,
  RESET_OVERRIDES,
  GET_REQUIREMENT_QUESTIONS,
  GET_SEARCH_QUESTIONS,
  INTACCT_ADMIN,
  // FORGOT PASSWORD
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  FIND_INVITATIONS,
  GENERAL_HELP,
  // KITS
  GET_KIT_FILTERS,
  GET_COUNTERPARTY_KITS,
  GET_KIT_COLUMNS,
  GET_KIT_QUESTIONS,
  KIT_SEARCH,
  SAVE_KIT,
  // CONNECTIONS/PROFILE
  CONNECTION_THEIR_TASKS,
  CONNECTION_TASKS,
  CONNECTION_RESET_SKELETON_INVITE,
  CONNECTION_CANCEL_INVITE,
  CONNECTION_UPDATE_INVITEEE,
  GET_HISTORY_DATA,
  UNHIDE_PRIVATE_PROFILE,
  GET_VISUAL_WORKFLOW,
  GET_LOOKUP,
  WORKFLOW_BUILDER,
  SET_CONNECTION_SKIP_IDENTITY_VERIFICATION,

  // ZENDESK
  ZENDESK_ADMIN,
  ZENDESK_SSO,
  // FILTERS/SAVED SEARCH
  SAVED_SEARCH_DELETE,
  SAVED_SEARCH_GET,
  SAVED_SEARCH_SAVE,
  // ADMIN QUESTION METADATA
  GET_ADMIN_QUESTIONS,
  UPDATE_ADMIN_QUESTIONS,
  UPDATE_ADMIN_LOCK,
  GET_ADMIN_LOCK,
  REPORTS,

  // VALUESETS
  GET_VALUESETS,
  ADD_VALUESETS,

  // TASKS
  GET_TASKS,
  SEARCH_TASKS,
  CREATE_TASK,
  UPDATE_TASK,
  DELETE_TASK,
  COMPLETE_TASK,
  CANCEL_TASK,
  ADD_TASK_COMMENT,

  // ACTION PLANS
  GET_ACTION_PLANS,

  // NOTES
  GET_NOTES,

  // EXPORTS
  EXPORT_HISTORY,

  // GIACT
  GET_GIACT_EMAIL,
  GET_GIACT_BANKING,
  GET_GIACT_SETTINGS,

  // SAM.GOV
  VALIDATE_SAM_CODE,
}
